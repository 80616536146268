export function elide(
  string: string,
  maxLength: number,
  separator = ' '
): string {
  if (string.length <= maxLength) {
    return string;
  }
  return string.substr(0, string.lastIndexOf(separator, maxLength)).concat('…');
}
