import './framework.scss';

import React from 'react';
import { graphql, Link, navigate } from 'gatsby';

import * as Core from 'components/core';
import * as Icons from 'components/framework/icons';

import Layout from 'components/layout/Layout';
import Sidebar from 'components/layout/Sidebar';
import FrameworkGraph from 'components/tree-diagrams/FrameworkGraph';
import DomainBreakdown from 'components/framework/DomainBreakdown';
import DomainsLegend from 'components/framework/DomainsLegend';
import {
  CompareDomainsLink,
  CompareFrameworksLink,
  CompareTermsLink
} from 'components/VisualToolLink';
import FrameworksGrid from 'components/FrameworksGrid';

import { Framework, Term } from 'types';

interface Props {
  data: {
    framework: Framework;
    allFrameworks: {
      frameworks: Framework[];
    };
    allTerms: {
      terms: Term[];
    };
    domainEmphasis: {
      domains: {
        identifier: string;
        count: number;
      }[];
    } | null;
  };
}

export default class FrameworkPage extends React.Component<Props> {
  get terms(): Term[] {
    const frameworkID = this.props.data.framework.identifier;
    return this.props.data.allTerms.terms.filter(
      (t) => t.frameworkID === frameworkID
    );
  }

  selectAssociatedFramework = (
    event: React.SyntheticEvent<HTMLSelectElement>
  ) => {
    const frameworkID = event.currentTarget.value;
    if (frameworkID != null) {
      navigate(`/frameworks/${frameworkID}/`);
    }
  };

  render() {
    const { framework } = this.props.data;
    const { frameworks } = this.props.data.allFrameworks;
    const associatedFrameworks = frameworks.filter(
      (f) => f.group === framework.group
    );
    const { terms } = this.props.data.allTerms;
    const domainEmphasis =
      this.props.data.domainEmphasis && this.props.data.domainEmphasis.domains;

    return (
      <Layout>
        <div className="FrameworkPage">
          <div className="FrameworkPage-body">
            <div className="FrameworkPage-content">
              {associatedFrameworks.length > 1 && (
                <div className="FrameworkPage-associatedFrameworks">
                  <h3>Select an age range</h3>
                  <Core.Select
                    value=""
                    onChange={this.selectAssociatedFramework}
                    placeholder="Choose related framework"
                  >
                    {associatedFrameworks.map((associatedFramework) => (
                      <option
                        key={associatedFramework.identifier}
                        value={associatedFramework.identifier}
                        disabled={
                          associatedFramework.identifier ===
                          framework.identifier
                        }
                      >
                        {associatedFramework.name}
                      </option>
                    ))}
                  </Core.Select>
                </div>
              )}

              <h1>{framework.name}</h1>
              {framework.name !== framework.fullName && (
                <h2 className="FrameworkPage-content-fullName">
                  {framework.fullName}
                </h2>
              )}

              <Core.Markdown>{framework.description}</Core.Markdown>

              {this.terms.length > 0 && (
                <>
                  <p className="no-print">
                    <Link to={`/frameworks/${framework.identifier}/terms`}>
                      View All Term Definitions
                    </Link>
                  </p>

                  <FrameworkGraph framework={framework} terms={this.terms} />
                </>
              )}

              {domainEmphasis && (
                <div>
                  <h2>Breakdown by Domain</h2>
                  <hr />
                  <h4>Domain Key</h4>
                  <DomainsLegend />
                  <DomainBreakdown domains={domainEmphasis} />
                </div>
              )}

              <div className="FrameworkPage-FrameworkDetails">
                <h2>Key Features</h2>
                <hr />
                {framework.contextAndCulture && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.ContextAndCulture className="FrameworkPage-dimensionIcon" />
                      Context & Culture
                    </h3>
                    <Core.Markdown>{framework.contextAndCulture}</Core.Markdown>
                  </div>
                )}
                {framework.developmentalPerspective && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.DevelopmentalPerspective className="FrameworkPage-dimensionIcon" />
                      Developmental Perspective
                    </h3>
                    <Core.Markdown>
                      {framework.developmentalPerspective}
                    </Core.Markdown>
                  </div>
                )}
                {framework.associatedOutcomes && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.AssociatedOutcomes className="FrameworkPage-dimensionIcon" />
                      Associated Outcomes
                    </h3>
                    <Core.Markdown>
                      {framework.associatedOutcomes}
                    </Core.Markdown>
                  </div>
                )}

                <h2>Available Resources</h2>
                <hr />
                {framework.supportMaterials && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.SupportMaterials className="FrameworkPage-dimensionIcon" />
                      Support Materials
                    </h3>
                    <Core.Markdown>{framework.supportMaterials}</Core.Markdown>
                  </div>
                )}
                {framework.programsAndStrategies && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.ProgramsAndStrategies className="FrameworkPage-dimensionIcon" />
                      Programs & Strategies
                    </h3>
                    <Core.Markdown>
                      {framework.programsAndStrategies}
                    </Core.Markdown>
                  </div>
                )}
                {framework.measurementTools && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.MeasurementTools className="FrameworkPage-dimensionIcon" />
                      Measurement Tools
                    </h3>
                    <Core.Markdown>{framework.measurementTools}</Core.Markdown>
                  </div>
                )}
                {framework.keyPublications && (
                  <div>
                    <h3 className="FrameworkPage-dimensionHeading">
                      <Icons.KeyPublications className="FrameworkPage-dimensionIcon" />
                      Key Publications
                    </h3>
                    <Core.Markdown>{framework.keyPublications}</Core.Markdown>
                  </div>
                )}
              </div>
            </div>
            <div className="FrameworkPage-sidebar-wrapper">
              <Sidebar>
                <h4 className="FrameworkPage-sidebar-label">Developer</h4>
                <p>
                  {framework.organizationURL ? (
                    <a href={framework.organizationURL}>
                      {framework.organization}
                    </a>
                  ) : (
                    framework.organization
                  )}
                </p>

                <h4 className="FrameworkPage-sidebar-label">Developer Type</h4>
                <p>{framework.organizationType}</p>
                <h4 className="FrameworkPage-sidebar-label">Purpose</h4>
                <p>{framework.purpose}</p>
                <h4 className="FrameworkPage-sidebar-label">Common Uses</h4>
                <p>{framework.commonUses}</p>

                <h4 className="FrameworkPage-sidebar-label">Key Parameters</h4>
                <div className="FrameworkPage-sidebar-key-parameter">
                  <div className="FrameworkPage-sidebar-key-parameter-label">
                    Age Range
                  </div>
                  <div className="FrameworkPage-sidebar-key-parameter-value">
                    {framework.age}
                  </div>
                </div>
                <div className="FrameworkPage-sidebar-key-parameter">
                  <div className="FrameworkPage-sidebar-key-parameter-label">
                    Setting
                  </div>
                  <div className="FrameworkPage-sidebar-key-parameter-value">
                    {framework.setting}
                  </div>
                </div>

                <h4 className="FrameworkPage-sidebar-label">Level of Detail</h4>
                <div className="FrameworkPage-sidebar-key-parameter">
                  <div className="FrameworkPage-sidebar-key-parameter-label">
                    Framework
                  </div>
                  <div className="FrameworkPage-sidebar-key-parameter-value">
                    {framework.frameworkLevelOfDetail}
                  </div>
                </div>
                <div className="FrameworkPage-sidebar-key-parameter">
                  <div className="FrameworkPage-sidebar-key-parameter-label">
                    Skills
                  </div>
                  <div className="FrameworkPage-sidebar-key-parameter-value">
                    {framework.skillsLevelOfDetail}
                  </div>
                </div>
                <div className="FrameworkPage-sidebar-key-parameter">
                  <div className="FrameworkPage-sidebar-key-parameter-label">
                    Observable Behaviors
                  </div>
                  <div className="FrameworkPage-sidebar-key-parameter-value">
                    {framework.observableBehaviors}
                  </div>
                </div>
                <div className="FrameworkPage-sidebar-key-parameter">
                  <div className="FrameworkPage-sidebar-key-parameter-label">
                    Learning Progression
                  </div>
                  <div className="FrameworkPage-sidebar-key-parameter-value">
                    {framework.learningProgression}
                  </div>
                </div>
              </Sidebar>

              <div className="FrameworkPage-sidebar-links no-print">
                <CompareDomainsLink displayStyle="thumbnail" />
                <CompareFrameworksLink
                  displayStyle="thumbnail"
                  state={{ frameworkID: framework.identifier }}
                />
                <CompareTermsLink displayStyle="thumbnail" />
              </div>

              <div>
                <Core.PrintButton />
              </div>
            </div>
          </div>

          <div className="no-print">
            <h3>Explore other Frameworks</h3>
            <FrameworksGrid
              frameworks={frameworks}
              terms={terms}
              excludedFramework={framework}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

// OPTIMIZE: Filter the terms to only those in this framework
export const query = graphql`
  query FrameworkPage($identifier: Int!) {
    framework: frameworksJson(identifier: { eq: $identifier }) {
      identifier
      group
      name
      fullName
      organization
      organizationType
      organizationURL
      description
      age
      setting
      purpose
      commonUses
      observableBehaviors
      learningProgression
      contextAndCulture
      developmentalPerspective
      associatedOutcomes
      supportMaterials
      programsAndStrategies
      measurementTools
      keyPublications
      color
      frameworkLevelOfDetail
      skillsLevelOfDetail
    }
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        group
        name
        fullName
        color
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkID
        frameworkName
        parentID
        tier
        definition
        includeInThesaurus
      }
    }
    domainEmphasis: frameworkDomainEmphasisJson(
      identifier: { eq: $identifier }
    ) {
      domains {
        identifier
        count
      }
    }
  }
`;
