import React, { useMemo } from 'react';
import { sortBy } from 'lodash';

import { useAllDomainDescriptions } from 'data/hooks/useDomainDescriptions';

import SingleStackedBar from 'components/visualization/single-stacked-bar/SingleStackedBar';

interface Props {
  domains: {
    count: number;
    identifier: string;
  }[];
}

const FrameworkDomainBreakdown: React.FC<Props> = (props) => {
  const { domains } = props;

  const domainDescriptions = useAllDomainDescriptions();

  const sortedDomains = useMemo(
    () => sortBy(domains, (domain) => parseInt(domain.identifier)),
    [domains]
  );

  const items = useMemo(() => {
    return sortedDomains.map((domain) => {
      const description = domainDescriptions.find(
        (description) => description.identifier === domain.identifier
      );

      return {
        id: domain.identifier,
        value: domain.count,
        color: description!.color,
        label: description?.name
      };
    });
  }, [domainDescriptions, sortedDomains]);

  return <SingleStackedBar items={items} />;
};

export default FrameworkDomainBreakdown;
