import './Tooltip.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
}

const Tooltip: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <div className="Tooltip">
      {children}
      <div className="Tooltip-arrow" />
    </div>
  );
};

export default Tooltip;
