import './DomainsLegend.scss';

import React from 'react';

import useDomainDescriptions from 'data/hooks/useDomainDescriptions';

import PrintBackground from 'components/core/PrintBackground';

const DomainsLegend: React.FC = () => {
  const domainDescriptions = useDomainDescriptions();

  return (
    <div className="FrameworkDomainsLegend">
      {domainDescriptions.map((domain) => (
        <div className="FrameworkDomainsLegend-item" key={domain.identifier}>
          <div
            className="FrameworkDomainsLegend-icon"
            style={{
              backgroundColor: domain.color
            }}
          >
            <PrintBackground color={domain.color} />
          </div>
          {domain.name}
        </div>
      ))}
    </div>
  );
};

export default DomainsLegend;
