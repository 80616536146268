import './FrameworkGraphTooltip.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { clamp } from 'lodash';

interface Props {
  label: string;
  frame: ClientRect;
  color: string;
}

export default class FrameworkGraphTooltip extends React.PureComponent<Props> {
  element: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.element = document.createElement('div');
  }

  componentDidMount() {
    this.body.appendChild(this.element);
  }

  componentWillUnmount() {
    this.body.removeChild(this.element);
  }

  get body(): HTMLBodyElement {
    return document.body as HTMLBodyElement;
  }

  get shouldRender(): boolean {
    return this.props.frame.width < 170 || this.props.label.length > 26;
  }

  render() {
    if (!this.shouldRender) {
      return null;
    }

    const { frame, color, label } = this.props;

    const top = frame.top + frame.height / 2.0 + window.scrollY;
    const left = clamp(
      frame.left + frame.width / 2.0 + window.scrollX,
      100,
      window.innerWidth - 100
    );

    return ReactDOM.createPortal(
      <div
        className="FrameworkGraphTooltip"
        style={{
          top: `${top}px`,
          left: `${left}px`,
          minWidth: frame.width,
          backgroundColor: color
        }}
      >
        {label}
      </div>,
      this.element
    );
  }
}
