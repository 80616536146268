import './FrameworkGraph.scss';

import React, { useMemo } from 'react';
import { tree as d3Tree } from 'd3-hierarchy';

import FrameworkGraphNode from './FrameworkGraphNode';

import { Size } from 'utils/geometry';
import {
  linkPath,
  transformCoordinates
} from 'components/tree-diagrams/d3-extensions';
import { stratifyFramework } from 'components/tree-diagrams/framework-attributes';

import { Framework, StateStandard, Term } from 'types';

interface Props {
  framework: Framework | StateStandard;
  terms: Term[];
}

const elementSize = new Size(150, 24);
const elementSpacing = new Size(50, 14);

const treeLayout = (terms: Term[], framework: Framework | StateStandard) => {
  const treeLayout = d3Tree()
    .separation((a, b) => (a.parent === b.parent ? 1.4 : 2.0))
    .nodeSize([elementSize.height, elementSize.width + elementSpacing.width])(
    stratifyFramework<Framework | StateStandard | Term>(framework, terms)
  ) as d3.HierarchyPointNode<Framework | StateStandard | Term>;

  const minY = Math.min(...treeLayout.descendants().map((d) => d.x));
  const maxY = Math.max(...treeLayout.descendants().map((d) => d.x));

  transformCoordinates(treeLayout, ({ x, y }) => ({
    x: y + elementSize.width / 2.0,
    y: x - minY + elementSize.height / 2.0
  }));

  const size = new Size(
    (treeLayout.height + 1) * elementSize.width +
      treeLayout.height * elementSpacing.width,
    minY * -1 + maxY + elementSize.height
  );

  return { rootNode: treeLayout, size };
};

/**
 * Renders a tree diagram of the terms in a framework. Expects the frameworks
 * and a flat list of all the terms in the framework.
 *
 * The diagram is drawn with a viewport size that increases with the number of
 * terms in the framework. The SVG is then scaled so a larger diagram will have
 * smaller text.
 */
const FrameworkGraph: React.FC<Props> = (props) => {
  const { framework, terms } = props;

  const { rootNode, size } = useMemo(() => {
    try {
      return treeLayout(terms, framework);
    } catch {
      return { rootNode: null, size: null };
    }
  }, [framework, terms]);

  if (!rootNode || !size) {
    return <div>Error generating tree diagram.</div>;
  }

  const nodes = rootNode.descendants();

  return (
    <svg
      className="FrameworkGraph"
      viewBox={`0 0 ${size.width} ${size.height}`}
    >
      {nodes.slice(1).map((node) => (
        <path
          key={node.id}
          className="link"
          d={linkPath(
            { x: node.x - elementSize.width / 2, y: node.y },
            {
              x: node.parent!.x + elementSize.width / 2,
              y: node.parent!.y
            }
          )}
        />
      ))}

      {nodes.map((node) => (
        <FrameworkGraphNode
          key={node.id}
          color={(framework as any).color || '#213153'}
          size={elementSize}
          node={node}
        />
      ))}
    </svg>
  );
};

export default FrameworkGraph;
