import './FrameworkGraphNode.scss';

import React from 'react';
import { navigate } from 'gatsby-link';
import classnames from 'classnames';

import { Size } from 'utils/geometry';
import { Term } from 'types';
import { elide } from 'utils/strings';
import FrameworkGraphTooltip from './FrameworkGraphTooltip';

interface Props {
  color: string;
  size: Size;
  node: d3.HierarchyPointNode<{ identifier: number; name: string }>;
  dimmed?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface State {
  hovering: boolean;
  frame: ClientRect | null;
}

export default class FrameworkGraphNode extends React.PureComponent<
  Props,
  State
> {
  element: Element | null = null;

  state: State = {
    hovering: false,
    frame: null
  };

  onClick = () => {
    const { data } = this.props.node;
    if ((data as Term).includeInThesaurus) {
      navigate(`/terms/${data.identifier}`);
    }
  };

  onMouseEnter = () => {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter();
    }

    if (this.element) {
      this.setState({
        hovering: true,
        frame: this.element.getBoundingClientRect()
      });
    }
  };

  onMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }

    this.setState({ hovering: false, frame: null });
  };

  render() {
    const { color, size, node, dimmed } = this.props;

    return (
      <>
        <g
          className={classnames('FrameworkGraphNode', {
            'is-hyperlink': (node.data as Term).includeInThesaurus
          })}
          onClick={this.onClick}
          transform={`translate(${node.x}, ${node.y})`}
        >
          <rect
            ref={(element) => (this.element = element)}
            className="FrameworkGraphNode-background"
            x={-size.width / 2}
            y={-size.height / 2}
            width={size.width}
            height={size.height}
            fill={color}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            style={{
              opacity: dimmed ? 0.5 : 1
            }}
          />
          <text
            dy="0.35em"
            className="FrameworkGraphNode-label"
            style={{ textAnchor: 'middle' }}
          >
            {elide(node.data.name, 26)}
          </text>
        </g>
        {this.state.hovering && this.state.frame && (
          <FrameworkGraphTooltip
            label={node.data.name}
            frame={this.state.frame}
            color={color}
          />
        )}
      </>
    );
  }
}
