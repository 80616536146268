import React from 'react';

interface Props {
  className?: string;
}

const DevelopmentalPerspective: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <polygon
        fill="#de682b"
        points="55.09 44.22 47.25 30.66 44.59 35.28 32.5 28.3 32.5 14.34 32.5 14.34 37.83 14.34 30 0.78 22.17 14.34 27.5 14.34 27.5 14.34 27.5 28.3 15.41 35.28 15.41 35.28 12.75 30.66 4.92 44.22 20.58 44.22 17.91 39.61 17.91 39.61 30 32.63 42.09 39.61 39.42 44.22 55.09 44.22"
      />
    </svg>
  );
};

export default DevelopmentalPerspective;
