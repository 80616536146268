import React from 'react';

interface Props {
  className?: string;
}

const KeyPublications: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <path
        fill="#db528e"
        d="M52,2.55h0L42.12,5.6,30,9.35h0L8,2.55v33.1l22,6.8h0l10.39-3.21L52,35.65h0ZM30,37.22,13,32V9.33l15.49,4.8,1.48.45h0V37.22Z"
      />
    </svg>
  );
};

export default KeyPublications;
