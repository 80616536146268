import React from 'react';

interface Props {
  className?: string;
}

const ProgramsAndStrategies: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <polygon
        fill="#f5b942"
        points="13.92 2.37 0.36 10.2 13.92 18.03 13.92 2.37"
      />
      <path
        fill="#f5b942"
        d="M52.2,1.91A7.43,7.43,0,0,0,45,11.25L36.6,17A32.15,32.15,0,0,0,13.92,7.7v5a27.13,27.13,0,0,1,18.47,7.23l-14.12,9.7a7.35,7.35,0,0,0-4.35-1.42,7.45,7.45,0,1,0,7.19,5.54L35.78,23.67A27.17,27.17,0,0,1,41.2,40h5a32.1,32.1,0,0,0-6.3-19.14l8-5.47A7.43,7.43,0,1,0,52.2,1.91Z"
      />
    </svg>
  );
};

export default ProgramsAndStrategies;
