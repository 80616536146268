import './Sidebar.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
}

const Sidebar: React.FC<Props> = (props) => {
  return <div className="Sidebar">{props.children}</div>;
};

export default Sidebar;
