import React from 'react';

interface Props {
  className?: string;
}

const MeasurementTools: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <polygon
        fill="#6a41df"
        points="49.91 13.5 41.21 13.5 41.21 37.65 34.35 37.65 34.35 2.35 25.65 2.35 25.65 37.65 18.79 37.65 18.79 23.43 10.09 23.43 10.09 37.65 10.09 37.65 10.09 42.65 49.91 42.65 49.91 40.15 49.91 40.15 49.91 13.5"
      />
    </svg>
  );
};

export default MeasurementTools;
