import React from 'react';

interface Props {
  className?: string;
}

const ContextAndEnvironment: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <path
        fill="#aec560"
        d="M39.21,12.6V5.18h-32v24H26A13.55,13.55,0,0,1,39.21,12.6Z"
      />
      <path
        fill="#aec560"
        d="M39.21,12.6V29.21H26A13.61,13.61,0,1,0,39.21,12.6Z"
      />
    </svg>
  );
};

export default ContextAndEnvironment;
