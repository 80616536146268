import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { sortBy, flatMap } from 'lodash';
import * as d3 from 'd3';

import { DomainDescription } from 'types';

function rangeOfColorsAround(color: string) {
  const a = d3.hsl(color);
  const b = d3.hsl(color);

  a.l = (a.l * 2 + 0) / 3;
  b.l = (b.l + 1) / 2;
  b.s += 0.5;

  return d3.interpolate(a, b);
}

function formatHexColor(colorString: string) {
  return (d3.color(colorString) as any).formatHex();
}

export default function useDomainDescriptions(): DomainDescription[] {
  const {
    allDomainDescriptions: { domainDescriptions }
  } = useStaticQuery<{
    allDomainDescriptions: {
      domainDescriptions: DomainDescription[];
    };
  }>(graphql`
    query {
      allDomainDescriptions: allDomainDescriptionsYaml {
        domainDescriptions: nodes {
          color
          description
          name
          identifier
          subdomains {
            name
            identifier
          }
        }
      }
    }
  `);

  return useMemo(() => {
    return domainDescriptions.map((domain) => {
      const colorScale = rangeOfColorsAround(domain.color);
      const sortedSubdomains = sortBy(domain.subdomains, (subdomain) =>
        parseInt(subdomain.identifier)
      );

      return {
        ...domain,
        subdomains: sortedSubdomains.map((subdomain, index) => ({
          ...subdomain,
          color: formatHexColor(colorScale(index / domain.subdomains.length)),
          description: ''
        }))
      };
    });
  }, [domainDescriptions]);
}

export function useSubdomainDescriptions() {
  const domainDescriptions = useDomainDescriptions();

  const subdomains = useMemo(
    () => flatMap(domainDescriptions, (d) => d.subdomains),
    [domainDescriptions]
  );

  return subdomains;
}

/**
 * All top level domains, and second level sub-domains with colors inferred
 * from subdomains.
 */
export function useAllDomainDescriptions() {
  const domainDescriptions = useDomainDescriptions();
  const subdomainDescriptions = useSubdomainDescriptions();

  const allDomains = useMemo(
    () => [...domainDescriptions, ...subdomainDescriptions],
    [domainDescriptions, subdomainDescriptions]
  );

  return allDomains;
}
