import React from 'react';

interface Props {
  className?: string;
}

const SupportMaterials: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <rect fill="#51aac9" x="7.17" y="33.71" width="45.65" height="8.7" />
      <rect fill="#51aac9" x="22.47" y="2.59" width="15.06" height="8.7" />
      <rect fill="#51aac9" x="14.94" y="18.15" width="30.13" height="8.7" />
    </svg>
  );
};

export default SupportMaterials;
