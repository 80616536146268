import React from 'react';

interface Props {
  className?: string;
}

const AssociatedOutcomes: React.FC<Props> = (props) => {
  return (
    <svg
      className="FrameworkIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 45"
      {...props}
    >
      <path
        fill="#3d55e4"
        d="M48.34,3.28a7.68,7.68,0,0,0-7.63,6.86L18.17,15.29a7.67,7.67,0,1,0-1.67,10l11.29,7a8,8,0,0,0-.2,1.71,7.69,7.69,0,1,0,2.84-6l-11.29-7a6.61,6.61,0,0,0,.15-.89L41.83,15A7.67,7.67,0,1,0,48.34,3.28Z"
      />
    </svg>
  );
};

export default AssociatedOutcomes;
