import './Cell.scss';

import React, { useState } from 'react';
import classnames from 'classnames';

import { isLight } from 'utils/colors';

import PrintBackground from 'components/core/PrintBackground';
import Tooltip from 'components/visualization/Tooltip';

interface Props {
  item: {
    id: string;
    value: number;
    label?: string;
    color: string;
  };
  total: number;
  showPercentLabels?: boolean;
  onClick?(id: string): void;
}

const Cell: React.FC<Props> = (props) => {
  const { item, total, showPercentLabels, onClick } = props;

  const [hovered, setHovered] = useState(false);

  const width = `${(item.value / total) * 100}%`;
  const percent = Math.round((item.value / total) * 100);

  const invertTextColor = isLight(item.color);

  return (
    <li
      className={classnames('SingleStackedBarCell', {
        'is-clickable': onClick != null
      })}
      key={item.id}
      style={{ background: item.color, width }}
      onClick={
        onClick
          ? () => {
              onClick(item.id);
            }
          : undefined
      }
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <PrintBackground color={item.color} />
      <div className="SingleStackedBarCell-srLabel">
        {item.label} {percent}%
      </div>

      {showPercentLabels && percent > 0 && (
        <div
          aria-hidden
          className={classnames('SingleStackedBarCell-label', {
            'is-inverted': invertTextColor
          })}
        >
          {percent}%
        </div>
      )}

      {hovered && (
        <Tooltip>
          {percent}%{item.label && <> - {item.label}</>}
        </Tooltip>
      )}
    </li>
  );
};

export default Cell;
