import './SingleStackedBar.scss';

import React from 'react';

import Cell from './Cell';

interface Props {
  items: {
    id: string;
    value: number;
    label?: string;
    color: string;
  }[];
  total?: number | null;
  showPercentLabels?: boolean;
  onClick?(id: string): void;
}

const SingleStackedBar: React.FC<Props> = (props) => {
  const { items, showPercentLabels, onClick } = props;

  const total =
    props.total != null
      ? props.total
      : items.reduce((total, item) => total + item.value, 0);

  return (
    <ul className="SingleStackedBar">
      {items.map((item) => (
        <Cell
          key={item.id}
          item={item}
          total={total}
          showPercentLabels={showPercentLabels}
          onClick={onClick}
        />
      ))}
    </ul>
  );
};

SingleStackedBar.defaultProps = {
  showPercentLabels: true
};

export default SingleStackedBar;
